import ShopifyLogo from '@/components/base/elements/Logo/ShopifyLogo';
import {
  ShopifyLogoColor,
  ShopifyLogoTheme,
} from '@/components/base/elements/Logo/types';

interface ResponsiveShopifyLogoProps {
  color?: ShopifyLogoColor;
  theme?: ShopifyLogoTheme;
}

export default function ResponsiveShopifyLogo({
  color = ShopifyLogoColor.Black,
  theme = ShopifyLogoTheme.ShopifyGreen,
}: ResponsiveShopifyLogoProps) {
  return (
    <div data-partial-name="shopifyLogoTitle">
      <ShopifyLogo
        className="md:hidden block h-[48px] mx-auto"
        logoWithText={false}
        logoTheme={theme}
        textColor={color}
      />
      <ShopifyLogo
        className="md:block hidden h-[48px] mx-auto"
        logoWithText
        logoTheme={theme}
        textColor={color}
      />
    </div>
  );
}
